@import "../../consts.scss";

.cta {
  margin-top: 160px;

  display: flex;
  width: 1680px;
  padding: 36px 35px;
  justify-content: space-between;
  align-items: flex-end;
  gap: 10px;

  border-radius: 24px;
  border: 2px solid var(--White, #F5FBFC);

  .left {
    display: flex;
    width: 456px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;

    .title {
      color: var(--White, #F5FBFC);

      font-family: "Montserrat Alternates", sans-serif;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;
    }
    .subtitle {
      color: var(--White, #F5FBFC);

      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
  }
  
  @media (max-width: $Size-mobile) {
    width: unset;

    flex-direction: column;
    align-items: flex-start;
    gap: 30px;

    .left {
      width: unset;
      .title {
        color: var(--White, #F5FBFC);
        font-family: "Montserrat Alternates", sans-serif;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
      }
      .subtitle {
        color: var(--White, #F5FBFC);
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
    }
    
  }
}