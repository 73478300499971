@import "../../consts.scss";

.testimonials {
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    margin-top: 160px;
    color: #F5F5F5;
    text-align: center;

    font-family: "Montserrat Alternates", sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }

  .carousel {
    margin-top: 60px;
    padding: 0 120px;
    box-sizing: border-box;
    width: 100vw;
    display: flex;
    flex-wrap: nowrap;
    overflow: scroll;
    align-items: flex-start;
    gap: 24px;

    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      height: 0 !important;
      background: transparent;
    }

    .card {
      display: flex;
      width: 828px;
      height: 300px;
      padding: 40px 60px;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      gap: 30px;

      border-radius: 24px;
      background: var(--White, #F5FBFC);
      flex-shrink: 0;

      .info {
        display: flex;
        align-items: center;

        .rating {
          display: flex;
          align-items: center;
          gap: 5px;
          color: var(--Primary-Black, #000A18);
          font-family: "Montserrat Alternates", sans-serif;
          font-size: 48px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-transform: capitalize;
        }
        .type {
          display: none;
          margin-left: 30px;
          height: 30px;
          padding: 5px 30px;
          justify-content: center;
          align-items: center;
          gap: 10px;

          border-radius: 20px;
          border: 2px solid var(--Primary-Black, #000A18);

          color: var(--Primary-Black, #000A18);

          font-family: "Roboto", sans-serif;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%;
        }
      }
      .text {
        color: var(--Primary-Black, #000A18);

        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;

        flex-grow: 1;
      }
      .client {
        display: flex;
        align-items: flex-start;
        gap: 10px;

        img {
          border-radius: 18px;
          width: 70px;
          height: 70px;
        }
        .details {
          display: flex;
          width: 416px;
          flex-direction: column;
          align-items: flex-start;
          gap: 5px;

          .name {
            color: var(--Primary-Black, #000A18);

            font-family: "Roboto", sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
          }
          .company {
            color: var(--Primary-Black, #000A18);
            font-family: "Roboto", sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
          }
        }
      }
    }
  }

  .scrollbar {
    display: flex;
    gap: 7px;
    margin-top: 30px;

    .other {
      width: 15px;
      height: 15px;
      border-radius: 20px;
      opacity: 0.5;
      background: var(--White, #F5FBFC);
      transition: all 300ms ease-out;
    }
    .current {
      width: 68px;
      height: 15px;
      border-radius: 20px;
      opacity: 0.8;
      background: var(--White, #F5FBFC);
      transition: all 300ms ease-out;
    }
  }
  @media (max-width: $Size-mobile) {
    .title {
      color: var(--White, #F5FBFC);
      text-align: center;
      font-family: "Montserrat Alternates", sans-serif;
      font-size: 21px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .carousel {
      padding: 0 16px;
      gap: 16px;
      .card {
        width: 282px;
        height: 297px;
        padding: 25px 10px;
        gap: 10px;
        opacity: 65%;

        &.current {
          opacity: 100%;
        }

        .info {
          .rating {
            color: var(--Primary-Black, #000A18);
            font-family: "Montserrat Alternates", sans-serif;
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: capitalize;
          }
          .type {
            color: var(--Primary-Black, #000A18);

            font-family: "Roboto", sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
          }
        }

        .text {
          color: var(--Primary-Black, #000A18);
          font-family: "Roboto", sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }
        .client {

          .details {
            width: unset;
            .name {
              color: var(--Primary-Black, #000A18);
              font-family: "Roboto", sans-serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%;
            }
            .company {
              color: var(--Primary-Black, #000A18);
              font-family: "Roboto", sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%;
            }
          }
        }

      }
    }

  }
}