@import "../../consts.scss";

.demos {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 160px 120px 0;
  color: var(--White, #F5FBFC);

  .title {
    color: #F5F5F5;
    text-align: center;
    margin-bottom: 60px;
  }

  .audio-player {
    display: flex;
    gap: 42px;
    padding: 40px 20px 20px;
    border-bottom: 0.5px solid var(--White, #f5fBFC);
    width: 100%;
    cursor: pointer;

    .button {
      display: flex;
      flex-shrink: 0;
      justify-content: center;
      align-items: center;
      width: 90px;
      height: 90px;
      border-radius: 93px;
      border: 2px solid var(--White, #F5FBFC);
    }

    .details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 765px;
      .audio-text {
        font-family: "Roboto", sans-serif;
        font-size: 21px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 31.5px */
      }
    }

    &.inactive {
      opacity: 50%;
    }
  }

  .video {
    display: flex;
    gap: 166px;
    margin-top: 160px;
    .video-player {
      display: flex;
      flex-direction: column;
      gap:20px;

      .video-text {
        font-family: "Roboto", sans-serif;
        font-size: 21px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 31.5px */
      }
    }
  }

  @media (max-width: $Size-mobile) {
    padding: 100px 16px 0;

    .audio-player {
      gap: 16px;

      .details {
        .audio-text {
          font-family: "Roboto", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 24px */
        }
      }
    }
    .video {
      flex-direction: column;
      margin-top: 100px;
      gap: 30px;

      .video-player {
        .video-text {
          font-family: "Roboto", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 24px */
        }
        video {
          width: 100%;
        }
      }
    }
  }
}