@import "../../consts.scss";

.awards {
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  gap: 60px;

  .title {
    color: #F5F5F5;
    text-align: center;
    font-family: "Montserrat Alternates", sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }
  .award {
    display: flex;
    width: 1680px;
    height: 705px;
    align-items: flex-start;
    gap: 142px;
    flex-shrink: 0;

    .left {
      display: flex;
      width: 284px;
      flex-direction: column;
      align-items: flex-start;
      gap: 30px;
      flex-shrink: 0;

      border-top: 1px solid #BFBFBF;
      padding-top: 20px;

      .award-title {
        color: var(--White, #F5FBFC);

        font-family: "Montserrat Alternates", sans-serif;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
      }

      .award-text {
        color: var(--White, #F5FBFC);

        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
    }

    img {
      width: 1254px;
      height: 705px;
      border-radius: 24px;
    }
  }
  
  @media (max-width: $Size-mobile) {
    .title {
      color: #F5F5F5;
      text-align: center;
      font-family: "Montserrat Alternates", sans-serif;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
    }

    .award {
      width: unset;
      height: unset;
      flex-direction: column;
      padding: 0 16px;
      gap: 10px;

      .left {
        width: unset;
        .award-title {
          color: var(--White, #F5FBFC);
          font-family: "Montserrat Alternates", sans-serif;
          font-size: 26px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: capitalize;
        }
        .award-text {
          color: var(--White, #F5FBFC);
          font-family: "Roboto", sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }
      }
      img {
        width: 100%;
        height: unset;
      }

    }
  }
}