@import "../consts.scss";

.navbar {
  position: fixed;

  display: flex;
  width: 100vw;
  box-sizing: border-box;
  padding: 20px 120px;
  justify-content: space-between;
  align-items: center;
  background: var(--Primary-Black, #000A18);
  z-index: 2;

  font-family: "Montserrat Alternates", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  .options {
    display: flex;
    align-items: center;
    gap: 10px;

    .link {
      color: rgba(245, 245, 245, 0.50);
      padding: 0 10px;
      cursor: pointer;
      transition: all 300ms ease-out;
      text-decoration: none;
      border-radius: 8px;

      &.active {
        color: rgba(245, 245, 245);
        background: var(--Neon-Pink, #F0A);
      }

      &:hover {
        color: rgba(245, 245, 245);
      }
    }

    .get-started {
      cursor: pointer;
      margin-left: 20px;
    }
  }

  .mobile-options {
    display: none;
    gap:5px;

    .get-started {
      display: flex;
      box-sizing: border-box;
      height: 32px;
      padding: 5px 15px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 10px;
      background: var(--Accent-Yellow, #FFEC51);
      text-decoration: none;
      color: var(--Primary, #161C25);
      border: 1px solid var(--Accent-Yellow, #FFEC51);

      font-family: "Montserrat Alternates", sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      transition: all 300ms ease-out;
    }

    .menu {
      display: flex;
      box-sizing: border-box;
      height: 32px;
      padding: 11px 12px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 10px;
      background: var(--Accent-Yellow, #FFEC51);
      border: 1px solid var(--Accent-Yellow, #FFEC51);
      transition: all 300ms ease-out;
    }
  }

  .mobile-menu {
    display: none;
    position: absolute;
    top: 0;
    left:0;
    z-index:-1;
    width: 100vw;
    height: 100vh;
    padding: 0 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background: var(--Prime-Yellow, #FFF4BF);
    transform: translateY(-100%);
    transition: transform 300ms ease-out;
    color: var(--Primary-Black, #000A18);
    .link {
      color: var(--Primary-Black, #000A18);
      font-family: "Montserrat Alternates", sans-serif;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
      text-decoration: none;
      margin-top: 25px;
      padding: 5px 10px;

      &.active {
        color: var(--Prime-Yellow, #FFF4BF);
        border-radius: 12px;
        background: var(--Primary-Black, #000A18);
      }
    }
    .message-title {
      margin-top: 100px;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }
    .email {
      color: var(--Primary-Black, #000A18);
      margin-top: 10px;
      font-family: "Montserrat Alternates", sans-serif;
      font-size: 21px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-decoration: none;
    }
    .copyright {
      margin-top: 60px;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }
    .address {
      margin-top: 10px;
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
  }

  &.open {
    .logo {
      .text {
        fill: var(--Primary-Black, #000A18);
      }
    }
    .mobile-options {
      .get-started {
        background: unset;
        border: 1px solid var(--Primary-Black, #000A18);
      }
      .menu {
        background: unset;
        border: 1px solid var(--Primary-Black, #000A18);

      }
    }
    .mobile-menu {
      transform: translateY(0);
    }
  }

  @media (max-width: $Size-mobile){
    padding: 10px 16px;

    .logo {
      width: 149px;
      .text {
        transition: all 300ms ease-out;
      }
    }
    .options {display: none}
    .mobile-options {
      display: flex;
    }
    .mobile-menu {
      display: flex;
    }
  }
}