@import "../../consts.scss";

.about-hero {
  width: 1920px;
  height: 1080px;
  background: url(../../../public/about-hero.jpeg) lightgray 50% / cover no-repeat;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 30px;

  padding-left: 120px;
  text-shadow: 0 0 20px rgba(6, 10, 29, 0.70);
  color: var(--White, #F5FBFC);

  .title {
    width: 1109px;
  }

  .subtitle {
    width: 1109px;
    padding-bottom: 56px;

    font-family: "Roboto", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }

  @media (max-width: $Size-mobile) {
    width: unset;
    height: 962px;
    padding-left: unset;
    justify-content: flex-start;

    .title {
      width: unset;
      color: var(--White, #F5FBFC);
      text-align: center;
      padding-top: 100px;
      text-shadow: 0 0 20px rgba(6, 10, 29, 0.70);
    }
    .subtitle {
      width: unset;
      color: var(--White, #F5FBFC);
      text-align: center;

      text-shadow: 0 0 20px rgba(6, 10, 29, 0.70);
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }
  }
}