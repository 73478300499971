@import "../consts.scss";

.careers {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--White, #F5FBFC);

  .hero {
    display: flex;
    margin-top: 160px;
    margin-bottom: 118px;
    width: 1112px;
    flex-direction: column;
    align-items: center;
    gap: 30px;

    .title {
      text-align: center;
    }
    .subtitle {
      text-align: center;

      font-family: "Roboto", sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }
  }
  .forms {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 22px;

    .careers-form {
      display: flex;
      box-sizing: border-box;
      width: 829px;
      height: 524px;
      padding: 40px 60px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      flex-shrink: 0;
      border-radius: 24px;
      border: 2px solid var(--White, #F5FBFC);
      transition: all 400ms ease-in;

      .body {
        margin-top: 60px;

        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }

      .bottom {
        display: flex;
        flex-grow: 1;
        align-items: flex-end;
      }

      &:hover {
        color: var(--Primary, #161C25);
        background: var(--Prime-Pink, #FFBFEA);
        border: 2px solid var(--Prime-Pink, #FFBFEA);
        box-shadow: 0 0 80px 0 #F0A;
      }
    }
    .voice-form {
      display: flex;
      box-sizing: border-box;
      width: 829px;
      height: 524px;
      padding: 40px 60px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      flex-shrink: 0;
      border-radius: 24px;
      border: 2px solid var(--White, #F5FBFC);
      transition: all 400ms ease-in;

      .body {
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
      .bottom {
        display: flex;
        flex-grow: 1;
        align-items: flex-end;
      }
      &:hover {
        color: var(--Primary, #161C25);
        background: var(--Prime-Blue, #BFEFFC);
        border: 2px solid var(--Prime-Blue, #BFEFFC);
        box-shadow: 0 0 80px 0 #00C1F3;
      }
    }
  }

  @media (max-width: $Size-mobile) {
    .hero {
      width: unset;
      .subtitle {
        text-align: center;
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
      }
    }
    .forms {
      flex-direction: column;

      .careers-form {
        width: unset;
        height: unset;
        padding: 25px 20px;

        .body {
          font-family: "Roboto", sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }
      }

      .voice-form {
        width: unset;
        height: unset;
        padding: 25px 20px;

        .body {
          font-family: "Roboto", sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }
        .bottom {
          font-family: "Roboto", sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }
      }
    }
  }
}