@import "../../consts.scss";

.brands {
  margin-top: 160px;
  .title {
    color: #F5F5F5;
    text-align: center;
  }

  .frame {
    display: flex;
    width: 1680px;
    padding: 36px 35px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 24px;
    border: 2px solid var(--White, #F5FBFC);
    margin-top: 60px;

    justify-content: space-between;

    .text {
      color: var(--White, #F5FBFC);
      width: 509px;
    }
    .icons {
      display: flex;
      width: 1101px;
      flex-direction: column;
      align-items: flex-start;
      gap: -41px;

      .row {
        display: flex;
        align-items: center;
        align-self: stretch;
        justify-content: space-evenly;

        .brand {
          padding: 10px;
          img {
            object-fit: contain;
            width: 200px;
            height: 169px;
          }
        }
      }
    }
  }

  @media (max-width: $Size-mobile) {
    .frame {
      width: unset;
      height: unset;
      flex-direction: column;
      align-items: center;
      gap:30px;
      padding: 10px;

      .text {
        width: unset;
        color: var(--White, #F5FBFC);
        text-align: center;
        font-size: 21px;
        font-weight: 600;
      }

      .icons {
        width: 100%;
        gap: 30px;

        .row {
          justify-content: space-between;
          .brand {
            img {
              width: 50px;
              height: 48px;
            }
          }
        }
      }
    }

  }
}