@import "../../consts.scss";

.team {
  margin-top: 160px;
  display: flex;
  flex-direction: column;

  .title {
    color: #F5F5F5;
    text-align: center;

    font-family: "Montserrat Alternates", sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }

  .join {
    display: flex;
    width: 1680px;
    justify-content: space-between;
    align-items: flex-end;
    align-self: center;

    .left {
      display: flex;
      width: 1254px;
      margin-top: 30px;
      flex-direction: column;
      align-items: flex-start;
      gap: 14px;

      .team-title {
        color: var(--White, #F5FBFC);

        font-family: "Montserrat Alternates", sans-serif;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
      }
      .team-text {
        color: var(--White, #F5FBFC);

        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
    }
  }
  .members {
    margin-top: 60px;
    display: flex;
    padding: 0 120px;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    align-self: stretch;

    .row {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      gap: 24px;
      align-self: stretch;

      .member {
        .image {
          width: 260px;
          height: 260px;
          border-radius: 260px;
          background: var(--color);

          transition: all 300ms ease-out;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 260px;
          }

          &:hover {
            box-shadow: 0 0 80px 0 var(--hover-color);
          }
        }
        .name {
          color: var(--White, #F5FBFC);
          text-align: center;

          font-family: "Montserrat Alternates", sans-serif;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          max-width: 260px;
        }
        .job-title {
          color: var(--Prime-Grey, #BFBFBF);
          text-align: center;

          font-family: "Roboto", sans-serif;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%;
          max-width: 260px;
        }
      }
    }
  }

  @media (max-width: $Size-mobile) {
    .title {
      display: none;
    }

    .join {
      flex-direction: column;
      margin: 0 10px;
      width: unset;
      gap: 30px;
      align-items: flex-start;

      .left {
        width: unset;
        .team-title {
          color: var(--White, #F5FBFC);
          text-align: center;
          font-family: "Montserrat Alternates", sans-serif;
          font-size: 26px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: capitalize;
        }
        .team-text {
          color: var(--White, #F5FBFC);
          font-family: "Roboto", sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }
      }

    }
    .members {
      width: 100vw;
      padding: 0;
      box-sizing: border-box;
      .row {
        overflow: scroll;
        justify-content: unset;
        padding: 0 16px;
        gap: 16px;
        .member {
          .image {
            height: 106px;
            width: 106px;
            svg {
              width: 100%;
              height: 100%;
            }
          }
          .name {
            color: var(--White, #F5FBFC);
            text-align: center;
            font-family: "Montserrat Alternates", sans-serif;
            font-size: 8.562px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
          .job-title {
            color: var(--Prime-Grey, #BFBFBF);
            text-align: center;
            font-family: "Roboto", sans-serif;
            font-size: 6.523px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
          }
        }
      }


    }

  }
}