@import "./consts.scss";

:root {
  --White: #F5FBFC;
  --Primary: #161C25;
  --Accent-Yellow: #FFEC51;
  --Primary-Black: #000A18;
  --Prime-Blue: #BFEFFC;
  --Prime-Pink: #FFBFEA;
  --Prime-Yellow: #FFF4BF;
}

body {
  margin: 0;
  font-family: "Montserrat Alternates", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--White, #F5FBFC);
  background: var(--Primary-Black, #000A18);
}

.h1 {
  font-family: "Raleway", sans-serif;
  font-size: 80px;
  font-weight: 700;
  text-transform: uppercase;
  
  @media (max-width: $Size-mobile) {
    font-size: 40px;
  }
}
.h2 {
  font-family: "Montserrat Alternates", sans-serif;
  font-size: 48px;
  font-weight: 600;
  text-transform: capitalize;
  
  @media (max-width: $Size-mobile) {
    font-size: 32px;
  }
}
.h3 {
  font-family: "Montserrat Alternates", sans-serif;
  font-size: 36px;
  font-weight: 700;
  text-transform: capitalize;

  @media (max-width: $Size-mobile) {
    font-size: 26px;
  }
}
.captions {
  font-family: "Montserrat Alternates", sans-serif;
  font-size: 24px;
  font-weight: 600;
  @media (max-width: $Size-mobile) {
    font-size: 21px;
  }
}
.paragraph1 {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 150%;

  @media (max-width: $Size-mobile) {
    font-size: 16px;
  }
}
.paragraph2 {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;

  @media (max-width: $Size-mobile) {
    font-size: 12px;
  }
}