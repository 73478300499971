@import "../../consts.scss";

.best-podcasts {
  position: relative;
  margin-top: 60px;
  .title {
    color: #F5F5F5;
    text-align: center;

    font-family: "Montserrat Alternates", sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;

    margin-bottom: 30px;
  }

  .row {
    display: flex;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    margin-top: 30px;

    .podcast {
      display: flex;
      width: 402px;
      flex-direction: column;
      align-items: flex-start;

      .image {
        position: relative;
        img {
          border-radius: 24px;
          width: 402px;
          height: 402px;
        }
        .overlay {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 24px;
          background: rgba(13, 26, 41, 0.70);
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          transition: all 300ms ease-out;

          .button {
            display: flex;
            align-items: center;
            cursor: pointer;
            width: 186px;
            height: 186px;
            border-radius: 93px;
            background: rgba(245, 251, 252, 0.50);
          }
        }

        &:hover {
          .overlay {
            opacity: 1;
          }
        }
      }

      .name {
        margin-top: 9.5px;
        color: var(--White, #F5FBFC);
        font-family: "Montserrat Alternates", sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .brand {
        margin-top: 12px;
        color: var(--White, #F5FBFC);
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        opacity: 0.5;
      }
    }
  }
  
  @media (max-width: $Size-mobile) {
    .title {
      color: #F5F5F5;
      text-align: center;

      font-family: "Montserrat Alternates", sans-serif;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
    }
    .row {
      padding: 0 16px;
      gap: 16px;
      .podcast {
        width: 100%;
        .image {
          img {
            width: 100%;
            height: unset;
          }
          .overlay {
            .button {
              width: 75px;
              height: 75px;
            }
          }
        }
        .name {
          color: var(--White, #F5FBFC);
          font-family: "Montserrat Alternates", sans-serif;
          font-size: 11.701px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: capitalize;
        }
        .brand {
          color: var(--White, #F5FBFC);
          font-family: "Roboto", sans-serif;
          font-size: 6.826px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }
      }
    }
  }
}