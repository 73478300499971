@import "../../consts.scss";

.hero {
  position: relative;
  width: 1680px;
  display: flex;
  flex-direction: column;

  .thumbnails {
    position: absolute;
    overflow: hidden;
    width: 100vw;

    display: flex;
    padding: 80px 0;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    flex-shrink: 0;

    .row {
      display: flex;
      align-items: center;
      gap: 24px;
      align-self: stretch;
    }

    .row3,.row4 {
      display: none;
    }

    .thumbnail {
      display: flex;
      width: 260px;
      height: 260px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border-radius: 24px;
      opacity: var(--opacity, 0.2);

      transition: opacity 400ms ease-out;

      &:hover {
        opacity: 0.4;
      }

      &.thumbnail2 {
        display: none;
      }
    }
  }
  .heading {
    display: flex;
    flex-direction: column;
    margin-top: 225px;
    width: 1136px;
    height: 759px;

    .welcome {
      color: var(--White, #F5FBFC);
      text-shadow: 0 0 20px rgba(6, 10, 29, 0.70);
      font-family: "Montserrat Alternates", sans-serif;
      font-size: 32px;
      font-weight: 500;
      z-index: 1;
      pointer-events: none;
    }

    .title {
      margin-top: 5px;
      color: var(--White, #F5FBFC);

      text-shadow: 0 0 20px rgba(6, 10, 29, 0.70);
      z-index: 1;
      pointer-events: none;
    }

    .subtitle {
      padding-top: 30px;
      color: #FFF;
      text-shadow: 0 0 20px rgba(6, 10, 29, 0.70);
      font-family: "Roboto", sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;

      z-index: 1;
      pointer-events: none;
    }

    .button {
      margin-top: 60px;
      align-self: flex-start;
    }
  }
  .more-arrow {
    align-self: center;
    margin-top: auto;
    animation: bounce 2s infinite;
  }
  @keyframes bounce {
    from, to, 80% {transform: translateY(-67px)}
    40% {transform: translateY(0)}
  }
  
  @media (max-width: $Size-mobile) {
    .thumbnails {
      width: 100vw;
      gap: 30px;
      padding: unset;

      .row {
        gap:14px;
        animation: publisherHero var(--speed, 5s) infinite linear;
        @keyframes publisherHero {
          0% {transform: translateX(0)}
          100% {transform: translateX(-924px)}
        }

        .thumbnail {
          width: 140px;
          height: 140px;

          &.thumbnail2 {
            display: unset;
          }
        }
      }
      .row3,.row4 {
        display: flex;
      }

    }
    width: unset;
    .heading {
      color: var(--White, #F5FBFC);
      box-sizing: border-box;
      width: 100vw;
      padding: 0 16px ;

      .welcome {
        text-shadow: 0 0 20px rgba(6, 10, 29, 0.70);
        font-size: 21px;
        font-weight: 600;
      }
    }
  }
}