@import "../../consts.scss";

.workTogether {
  padding: 0 120px;
  .title {
    padding-top: 230px;
    color: #F5F5F5;
    text-align: center;
  }

  .container {
    margin-top: 60px;
    display: flex;
    align-items: flex-start;
    gap: 24px;

    .left {
      flex-basis: 100%;
      display: flex;
      flex-grow: 1;
      flex-shrink: 1;
      padding: 35px 40px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      border-radius: 24px;
      border: 2px solid var(--White, #F5FBFC);
      color: var(--White, #F5FBFC);
      transition: all 300ms ease-out;

      .subheader {
        font-family: "Roboto", sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
      }

      .item {
        display: flex;
        align-items: flex-start;
        gap: 28px;
        margin-top: 50px;

        .icon {
          display: flex;
          height: 50px;
        }

        .texts {
          .bottom {
            margin-top: 10px;
            font-family: "Roboto", sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
          }
        }
      }
      .button {
        margin-top: 20px;
        align-self: center;
      }

      &:hover {
        background: var(--Prime-Pink, #FFBFEA);
        box-shadow: 0 0 80px 0 #F0A;
        color: var(--Primary-Black, #FFBFEA);
        border: 2px solid var(--Prime-Pink, #FFBFEA);

        .icon {
          svg {
            rect { stroke: var(--Primary-Black, #000A18);}
            path { fill: var(--Primary-Black, #000A18);}
          }
        }
      }
    }

    .right {
      flex-basis: 100%;
      display: flex;
      flex-grow: 1;
      flex-shrink: 1;
      max-width: 686px;
      padding: 35px 40px;
      flex-direction: column;
      align-items: flex-start;
      gap: 30px;
      border-radius: 24px;
      border: 2px solid var(--White, #F5FBFC);
      color: var(--white, #F5FBFC);
      transition: all 300ms ease-out;

      .subheader {
        font-family: "Roboto", sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
      }

      .form {
        display: flex;
        align-items: center;
        gap: 30px;
        align-self: stretch;

        .email {
          display: flex;
          height: 40px;
          padding: 10px 15px;
          align-items: flex-start;
          gap: 10px;
          align-self: stretch;
          border-radius: 24px;
          border: 2px solid var(--White, #F5FBFC);
          background: unset;

          font-family: "Roboto", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          flex-grow: 1;

          &::placeholder {
            opacity: 1;
          }
        }
      }

      .error {
        box-sizing: border-box;
        width: 100%;
        color: var(--Primary-Black, #000A18);
        padding: 10px 15px;
        border-radius: 24px;
        border: 2px solid #FF5858;
        background: #FFBFBF;
      }

      &:hover {
        background: var(--Prime-Blue, #BFEFFC);
        box-shadow: 0 0 80px 0 #00C1F3;
        color: var(--Primary-Black, #FFBFEA);
        border: 2px solid var(--Prime-Blue, #BFEFFC);
        .email {
          border: 2px solid var(--Primary-Black, #FFBFEA);

        }
      }
      &.submitted {
        padding: 100px 0;
        align-items: center;
        border: unset;
      }
    }
  }

  @media (max-width: $Size-mobile) {
    padding: 0 19px;

    .container {
      flex-direction: column;
      .left {
        width: unset;
        .subheader {
          font-family: "Roboto", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%;
        }
        .item {
          margin-top: 30px;
          .texts {
            .bottom {
              font-family: "Roboto", sans-serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%;
            }
          }
        }
      }
      .right {
        width: unset;
        .subheader {
          font-family: "Roboto", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%;
        }
        .form {
          flex-direction: column;
          align-items: flex-start;
          gap: 15px;
        }
      }
    }
  }
}
