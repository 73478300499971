@import "../consts.scss";

.blog {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 60px;
    margin-top: 160px;


    .case-study {
        cursor: pointer;
        padding: 0 10px;
        img {
            border-radius: 24px;
            max-width: 100%;
        }
        .subtitle {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        text-decoration: unset;
        color: white;
    }

}