@import "../../consts.scss";

.services {
  margin-top: 160px;
  padding: 0 120px;

  .title {
    position: relative;
    color: #F5F5F5;
    text-align: center;
    margin-bottom: 60px;
  }

  .list {
    display: flex;
    flex-direction: column;
    gap: 180px;
  }

  @media (max-width: $Size-mobile) {
    display: flex;
    padding: 0 20px;
    flex-direction: column;
    align-self: stretch;
  }
}