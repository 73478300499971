@import "../../consts.scss";

.what-we-do {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    font-family: "Montserrat Alternates", sans-serif;

    .h2 {
        margin-bottom: 60px;
    }

    .item {
        display: flex;
        flex-direction: row;
        margin-bottom: 60px;
        padding: 0 120px;
        gap: 188px;

        &.odd {
            flex-direction: row-reverse;
        }

        .image {
            flex-basis: 0;
            flex-grow: 1;

            img {
                width: 100%;
            }
        }

        .text {
            flex-basis: 0;
            flex-grow: 1;

            .title {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap:15px;
                font-size: 36px;
                font-weight: 700;
                text-transform: capitalize;
                padding-bottom: 30px;

                .number {
                    font-size: 212px;
                    font-weight: 700;
                }
           }

            .body {
                font-size: 24px;
                font-weight: 600;
            }
        }

        .body-mobile {
            display: none;
            font-size: 21px;
            font-weight: 600;
        }

        @media (max-width: $Size-desktop) {
            padding: 0 120px;
            gap: 188px;
            
            .text {
                gap: 30px;
                .title {
                    font-size: 36px;
                    flex-direction: row;
                    align-items: center;
                    padding-bottom: unset;

                    .number {
                        font-size: 180px;
                    }
                }

                .body {
                    font-size: 24px;
                }
            }

            @media (max-width: $Size-tablet) {
                padding: 0 32px;
                gap: 16px;

                .text {
                    gap: 30px;
                    .title {
                        font-size: 26px;
                        flex-direction: row;
                        align-items: center;
                        padding-bottom: unset;

                        .number {
                            font-size: 100px;
                        }
                    }

                    .body {
                        font-size: 21px;
                    }
                }


                @media (max-width: $Size-mobile) {
                    padding: 0 16px;
                    flex-direction: column-reverse;

                    &.odd {
                        flex-direction: column-reverse;
                    }    

                    .body-mobile {
                        display: unset;
                    }

                    .text {
                        .title {
                            align-items: flex-start;
                            flex-direction: row;
                            align-items: center;
                            padding-bottom: unset;

                            .desc {
                                padding-top: 25px;
                            }
                        }
                        
                        .body {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    
}