@import "../consts.scss";

.work {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 160px;
  color: var(--White, #F5FBFC);

  .hero {
    width: 1112px;
    .title {
      text-align: center;
    }
    .subtitle {
      margin-top: 30px;
      text-align: center;
    }
  }
  .all-set {
    margin-top: 117px;
    padding-top: 224px;
    height: 771px;
    box-sizing: border-box;
    .title {
      text-align: center;
    }
    .subtitle {
      padding-top: 30px;
      text-align: center;
      font-family: "Roboto", sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }
  }


  .work-form {
    margin-top: 118px;
    display: flex;
    width: 828px;
    padding: 35px 40px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 24px;
    background: var(--White, #F5FBFC);
    color: var(--Primary-Black, #000A18);

    .name-label {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      margin-top: 30px;
    }
    .name-input {
      display: flex;
      padding: 10px 15px;
      align-items: flex-start;
      gap: 10px;
      flex: 1 0 0;
      align-self: stretch;
      border-radius: 12px;
      border: 1px solid var(--Primary-Black, #000A18);
      background: unset;

      &::placeholder {
        color: var(--Prime-Grey, #BFBFBF);
        font-family: "Roboto", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
    }
    .details {
      display: flex;
      align-items: flex-start;
      gap: 20px;
      align-self: stretch;
      margin-top: 20px;
      .email{
        flex-grow: 1;
        .email-label{
          font-family: "Roboto", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }
        .email-input{
          background: unset;
          display: flex;
          padding: 10px 15px;
          align-items: flex-start;
          gap: 10px;
          align-self: stretch;
          border-radius: 12px;
          border: 1px solid var(--Primary-Black, #000A18);
          box-sizing: border-box;
          width: 100%;

          &::placeholder {
            color: var(--Prime-Grey, #BFBFBF);
            font-family: "Roboto", sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
          }
        }
      }
      .phone {
        flex-grow: 1;
        .phone-label{
          font-family: "Roboto", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }
        .phone-input{
          display: flex;
          padding: 10px 15px;
          align-items: flex-start;
          gap: 10px;
          flex: 1 0 0;
          align-self: stretch;
          border-radius: 12px;
          border: 1px solid var(--Primary-Black, #000A18);
          box-sizing: border-box;
          background: unset;
          width: 100%;
          &::placeholder {
            color: var(--Prime-Grey, #BFBFBF);

           
            font-family: "Roboto", sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
          }

        }
      }
    }
    .have-podcast {
      cursor: pointer;
      margin-top: 20px;
      display: flex;
      padding: 5px 20px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 12px;
      border: 1px solid var(--Primary-Black, #000A18);
      color: var(--Prime-Grey, #BFBFBF);

      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      transition: all 300ms ease-out;

      svg {
        display: none;
      }

      &:hover {
        background: rgba(191, 239, 252, 0.50);
        svg {
          display: inline;
        }
      }

      &.checked {
        background: var(--Prime-Blue, #BFEFFC);
        color: var(--Primary-Black, #000A18);
        svg {
          display: inline;
          #Vector {
            fill: var(--Primary-Black, #000A18);
          }
        }
      }
    }
    .describe {
      margin-top: 60px;
    }
    .description {
      margin-top: 40px;
      display: flex;
      height: 133px;
      box-sizing: border-box;
      padding: 10px 15px;
      align-items: flex-start;
      gap: 10px;
      align-self: stretch;
      border-radius: 12px;
      border: 1px solid var(--Primary-Black, #000A18);
      resize: none;
      background: unset;

      &::placeholder {
        color: var(--Prime-Grey, #BFBFBF);

        font-family: "Roboto", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
    }
    .button {
      align-self: flex-end;
      margin-top: 60px;
    }
  }

  @media (max-width: $Size-mobile) {
    .hero {
      width: unset;
    }
    .work-form {
      width: unset;
      padding: 25px 20px;
      margin: 100px 16px;

      .name-label {
        color: var(--Primary-Black, #000A18);
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }

      .details {
        flex-direction: column;

        .email {
          width: 100%;
          .email-label {
            color: var(--Primary-Black, #000A18);
            font-family: "Roboto", sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
          }
        }

        .phone {
          width: 100%;
          .phone-label {
            color: var(--Primary-Black, #000A18);
            font-family: "Roboto", sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
          }
        }

      }
      .have-podcast {
        box-sizing: border-box;
        width: 100%;
      }
    }
  }
}