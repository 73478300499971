@import "../consts.scss";

.footer {
  display: none;
  margin-top: 160px;
  width: 100vw;
  box-sizing: border-box;
  //display: flex;
  padding: 40px 120px;
  justify-content: space-between;
  flex-shrink: 0;
  align-items: flex-start;
  border-radius: 24px 24px 0 0;
  color: var(--Primary-Black, #000A18);
  background: var(--Prime-Yellow, #FFF4BF);

  .contact-us {
    display: flex;
    width: 402px;
    flex-direction: column;
    align-items: flex-start;
    gap: 60px;
    flex-shrink: 0;

    .address {
      display: flex;
      gap: 34px;

      .text {
        font-family: "Roboto", sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
      }
    }

    .email {
      display: flex;
      gap: 37px;
      margin-top: -30px;

      .text {
        font-family: "Roboto", sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
      }
    }

    .form {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 30px;
      align-self: stretch;

      .email {
        display: flex;
        height: 24px;
        padding: 10px 15px;
        align-items: flex-start;
        gap: 10px;
        align-self: stretch;
        border-radius: 12px;
        border: 1px solid var(--Primary-Black, #000A18);
        background: unset;

        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;

        &::placeholder {
          opacity: 1;
          color: var(--Primary-Black, #000A18);
        }
      }

      .message {
        display: flex;
        height: 110px;
        padding: 10px 15px;
        align-items: flex-start;
        gap: 10px;
        align-self: stretch;
        border-radius: 12px;
        border: 1px solid var(--Primary-Black, #000A18);
        background: unset;

        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;

        &::placeholder {
          opacity: 1;
          color: var(--Primary-Black, #000A18);
        }
      }
    }

    .button {
      align-self: flex-end;
    }
  }
  .info {
    display: flex;
    width: 1112px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    flex-shrink: 0;
    align-self: stretch;

    .menu {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      .column {
        display: flex;
        width: 183px;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;

        .title {
          margin-bottom: 20px;
        }

        .items {
          display: flex;
          flex-direction: column;
          gap: 10px;

          .item {
            font-family: "Roboto", sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
          }
        }
      }
    }
  }
  .social {
    display: flex;
    width: 402px;
    flex-direction: column;
    align-items: flex-end;
    gap: 30px;

    .links {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      align-content: flex-start;
      gap: 10px;
      align-self: stretch;
      flex-wrap: wrap;

      .link {
        display: flex;
        cursor: pointer;
        padding: 5px 20px;
        justify-content: center;
        align-items: center;
        gap: 10px;

        border-radius: 12px;
        border: 1px solid var(--Primary-Black, #000A18);

        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;

        &:hover {
          background: var(--Primary-Black, #000A18);
          color: var(--Prime-Yellow, #FFF4BF);
        }
      }
    }
  }
  @media (max-width: $Size-mobile) {
    padding: 25px 16px;
    flex-direction: column;
    .contact-us {
      width: 100%;
      .address {
        .text {
          font-family: "Roboto",sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%;
        }
      }
      .email {
        .text {
          font-family: "Roboto", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%;
        }
      }
    }
    .form {
      .email {
        width: unset;
      }
      .message {
        width: unset;
      }
    }
    .info {
      flex-direction: column;
      align-items: flex-start;
      width: unset;
      gap: 60px;

      .menu {
        flex-direction: column;
        gap: 30px;
        .column {
          flex-direction: row;
          width: 100%;

          .title {
            width: 106px;
            font-family: "Roboto", sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;

          }
          .items {
            .item {
              font-family: "Roboto", sans-serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%;
            }
          }
        }
      }
      .social {
        width: unset;

        .links {
          font-family: "Roboto", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }
      }
    }
  }
}