@import "../../consts.scss";

.partners {
  margin-top: 160px;
  display: flex;
  flex-direction: column;
  width: 100vw;
  align-items: center;

  .title{
    color: #F5F5F5;
    text-align: center;

    font-family: "Montserrat Alternates", sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }

  .frame {
    display: flex;
    margin: 60px 120px 0;
    align-self: stretch;
    padding: 36px 35px;
    align-items: stretch;
    border-radius: 24px;
    border: 2px solid var(--White, #F5FBFC);

    justify-content: space-between;

    .left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      .prompt {
        color: var(--White, #F5FBFC);
        width: 509px;
        font-family: "Montserrat Alternates", sans-serif;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
      }
      .mobile-icons {
        display: none;
      }
    }

    .icons {
      display: flex;
      align-items: center;
      align-self: stretch;
      justify-content: space-evenly;
      flex-grow: 1;
      gap: 10px;

      .brand {
        max-width: 200px;
        max-height: 200px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .prev_title{
    margin-top: 160px;
    color: #F5F5F5;
    text-align: center;

    font-family: "Montserrat Alternates", sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }

  .prev_icons {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    align-self: stretch;
    justify-content: space-evenly;
    margin: 60px 120px 0;
    overflow: hidden;
    .marquee {
      display: flex;
      gap: 60px;
      .brand {
        img {
          object-fit: contain;
          width: 200px;
          height: 169px;
        }
      }

      animation: marquee var(--speed, 45s) infinite linear;
      @keyframes marquee {
        0% {
          transform: translateX(0)
        }
        100% {
          transform: translateX(-6500px)
        }
      }
    }
  }

  @media (max-width: $Size-mobile) {
    .title {
      color: #F5F5F5;
      text-align: center;
      font-family: "Montserrat Alternates", sans-serif;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
    }

    .frame {
      flex-direction: column;
      width: unset;
      height: unset;
      margin: unset;
      padding: 30px 0 0 0;
      border: unset;
      gap: 10px;

      .left {
        width: unset;
        gap: 30px;
        padding: 10px;
        margin: 0 10px;
        border-radius: 24px;
        border: 2px solid var(--White, #F5FBFC);

        .prompt {
          width: unset;
          color: var(--White, #F5FBFC);

          font-family: "Montserrat Alternates", sans-serif;
          font-size: 21px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;

        }
        .mobile-icons {
          display: flex;
          flex-direction: row;
          align-self: stretch;
          justify-content: space-evenly;
          .brand {
            width: 70px;
            height: 70px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .button {
          align-self: flex-end;
        }
      }

      .icons {
        display: none;
      }
    }
    .prev_title {
      margin-top: 100px;
      color: #F5F5F5;
      text-align: center;
      font-family: "Montserrat Alternates", sans-serif;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
    }

    .prev_icons {
      margin: 14px 16px 0;
      .marquee {
        .brand {
          img {
            object-fit: contain;
            width: 90px;
            height: 90px;
          }
        }
        animation: marquee-mobile var(--speed, 45s) infinite linear;
        @keyframes marquee-mobile {
          0% {
            transform: translateX(0)
          }
          100% {
            transform: translateX(-3750px)
          }
        }
      }
    }
  }
}