@import "../../consts.scss";

.podcasts-row {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 30px;
  color: var(--White, #F5FBFC);

  .podcast {
    display: flex;
    width: 402px;
    flex-direction: column;
    align-items: flex-start;

    .image {
      position: relative;
      img {
        border-radius: 24px;
        width: 402px;
        height: 402px;
      }
      .overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 24px;
        background: rgba(13, 26, 41, 0.70);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transition: all 300ms ease-out;

        .button {
          display: flex;
          align-items: center;
          cursor: pointer;
          width: 186px;
          height: 186px;
          border-radius: 93px;
          background: rgba(245, 251, 252, 0.50);
        }
      }

      &:hover {
        .overlay {
          opacity: 1;
        }
      }
    }

    .name {
      margin-top: 9.5px;
    }

    .brand {
      margin-top: 12px;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      opacity: 0.5;
    }
  }

  @media (max-width: $Size-mobile) {
    flex-flow: wrap;
    gap: unset;
    justify-content: space-evenly;
    margin-top: 0;
    .podcast {
      width: unset;
      .image {
        margin-top: 30px;
        img {
          width: 196px;
          height: 196px;
        }
      }

      .name {
        margin-top: 5px;
        font-size: 11.701px;
      }

      .brand {
        font-family: "Roboto", sans-serif;
        font-size: 6.826px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        margin-top: 0;
      }
    }
  }
}
