@import "../consts.scss";

.button {
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: all 400ms ease-in;
  cursor: pointer;
  text-decoration: none;

  .text {
    transition: all 400ms ease-in;
    display: flex;
    height: 30px;
    padding: 15px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: 21px;
    background: var(--bg);
    border: var(--border);

    color: var(--color);
    font-family: "Montserrat Alternates", sans-serif;
    font-size: 28px;
    font-weight: 600;
  }

  .arrow {
    transition: all 400ms ease-in;
    position: relative;
    display: flex;
    height: 28px;
    padding: 16px 15px;
    justify-content: center;
    align-items: center;
    left:0;

    border-radius: 18px;
    background: var(--bg);
    border: var(--border);
    svg .arrow {
      fill: var(--color);
    }
  }

  &:hover {
    .text {
      padding: 15px 50px 15px 50px;
      background: var(--bg-hover);
      color: var(--color-hover);
    }
    .arrow {
      padding: 16px 40px 16px 20px;
      left: -50px;
      margin-right: -50px;
      background: var(--bg-hover);
      svg .arrow {
        fill: var(--color-hover);
      }
    }
  }

  @media (max-width: $Size-mobile) {
    .text {
      font-size: 18px;
      padding: 10px 30px;
    }
    .arrow {
      padding: 11px 12px;
    }
  }
}