@import "../consts.scss";

.podcasts {
  display: flex;
  flex-direction: column;
  align-items: center;

  .hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    padding-top: 160px;
    padding-bottom: 148px;
    color: var(--White, #F5FBFC);

    .title {
      text-align: center;
    }
    .subtitle {
      text-align: center;

      font-family: "Roboto", sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }
  }

  .featured {
    color: #F5F5F5;
    width: 1680px;
  }

  .more {
    color: #F5F5F5;
    width: 1680px;
    margin-top: 60px;
  }

  .show-more {
    margin-top: 60px;
    cursor: pointer;

    display: inline-flex;
    padding: 10px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 21px;
    border: 1px solid var(--Prime-Blue, #BFEFFC);

    color: var(--Prime-Blue, #BFEFFC);

    font-size: 28px;
    font-weight: 600;

    &:hover {
      background: rgba(191, 239, 252, 0.50);
      color: var(--Primary, #161C25);
    }
  }

  @media (max-width: $Size-mobile) {
    .hero {
      .subtitle {
        color: var(--White, #F5FBFC);
        text-align: center;

       
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
      }
    }
    .featured {
      align-self: flex-start;
      color: #F5F5F5;
      width: unset;
    }
    .more {
      width: unset;
      align-self: flex-start;
      color: #F5F5F5;
    }

    .show-more {
      color: var(--Prime-Blue, #BFEFFC);
      font-size: 18px;
    }
  }
}