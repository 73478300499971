@import "../../consts.scss";

.service {
  position: relative;
  height: 403px;
  display: flex;
  padding: 60px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 24px;
  color: var(--Primary-Black, #000A18);

  .details {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;

    .name {
      margin-bottom: 60px;
    }

    .columns {
      display: flex;
      gap: 24px;
      flex-grow: 1;

      .text {
        font-family: "Roboto", sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
      }

      .tags {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap:10px;
        width: 50%;
        flex-grow: 0;
        flex-shrink: 0;

        .tag {
          display: flex;
          align-items: center;
          padding: 0 5px;
          gap: 5px;
          font-family: "Roboto", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          cursor: default;

          &.link {
            cursor: pointer;

            .check-out {
              display: none;
            }

            &:hover {
              font-weight: bold;
              text-decoration: underline;
            }
          }
        }
      }
    }

    .start-project {
      margin-top: 29px;
      align-self: flex-start;
    }
  }

  .image {
    position: relative;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    width: 526px;
    height: 363px;
    padding: 20px 50px;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    max-width: 30%;
    color: var(--White, #F5FBFC);

    .number {
      text-align: center;
      font-size: 120px;
    }

    .description {
      text-align: center;

      font-family: "Roboto", sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }
  }

  &.pink {
    background: var(--Prime-Pink, #FFBFEA);
    box-shadow: 0 0 80px 0 #F0A;
  }

  &.yellow {
    background: var(--Prime-Yellow, #FFF4BF);
    box-shadow: 0 0 80px 0 #FFD300;
  }

  &.blue {
    background: var(--Prime-Blue, #BFEFFC);
    box-shadow: 0 0 80px 0 #00C1F3;
  }

  @media (max-width: $Size-mobile) {
    flex-direction: column;
    height: unset;
    padding: 25px 20px;
    .details {
      .columns {
        flex-direction: column;
        .text {
          width: unset;

          font-family: "Roboto", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%;
        }
        .tags {
          width: unset;


          font-family: "Roboto", sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }
      }
    }
    .image {
      display: flex;
      box-sizing: border-box;
      width: 100%;
      height: unset;
      aspect-ratio: 1.51;
      padding: 20px;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      margin-top: 60px;
      max-width: unset;

      .number{
        font-size: 80px;
      }
    }
  }
}