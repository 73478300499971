@import "../../consts.scss";

.home-hero {
  position: relative;
  box-sizing: border-box;
  width: 100vw;
  padding: 0 120px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .heading {
    display: flex;
    flex-direction: column;
    width: 1136px;
    height: 759px;
    .spline {
      position: absolute;
      width:1920px !important;
      height:1080px !important;
    }

    .welcome {
      margin-top: 225px;
      color: var(--White, #F5FBFC);
      text-shadow: 0 0 20px rgba(6, 10, 29, 0.70);
      font-size: 32px;
      font-weight: 500;
      z-index: 1;
      pointer-events: none;
    }

    .title {
      margin-top: 5px;
      color: var(--White, #F5FBFC);

      text-shadow: 0 0 20px rgba(6, 10, 29, 0.70);
      z-index: 1;
      pointer-events: none;
    }

    .subtitle {
      padding-top: 30px;
      color: #FFF;
      text-shadow: 0 0 20px rgba(6, 10, 29, 0.70);
      font-family: "Roboto", sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;

      z-index: 1;
      pointer-events: none;
    }

    .button {
      margin-top: 60px;
      align-self: flex-start;
    }
  }
  .more-arrow {
    align-self: center;
    margin-top: auto;
    animation: bounce 2s infinite;
  }
  @keyframes bounce {
    from, to, 80% {transform: translateY(-67px)}
    40% {transform: translateY(0)}
  }
  
  @media (max-width: $Size-mobile) {
    width: unset;
    .heading {
      color: var(--White, #F5FBFC);
      box-sizing: border-box;
      width: 100vw;
      padding: 0 16px ;

      .spline {
        top: 135px;
        width: 100vw !important;
        height: 420px !important;
        canvas {
          width: 100% !important;
          height: 100% !important;
        }
      }

      .welcome {
        font-size: 21px;
        font-weight: 600;
      }
    }
  }
}